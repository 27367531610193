<template>
  <BRow>
    <BCol
      cols="12"
      class="mb-2"
    >
      <span class="text-[32px] text-black">
        <strong>
          Dashboard
        </strong>
      </span>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard>
        <BRow class="mb-3 justify-content-around">
          <BCol
            sm="auto"
            xs="10"
            class="mb-1"
          >
            <div class="card-users mb-2">
              <div class="d-flex align-items-center pt-1 pl-1 mb-50">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/profile-tick.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black">
                  <strong>
                    User Terdaftar
                  </strong>
                </span>
              </div>

              <div
                style="border-bottom: 1px solid #E2E2E2;"
                class="pb-1"
              >
                <span class="text-black text-[20px] p-1">
                  Total : <span class="text-info">
                    <strong>
                      {{ userRegisterCount.total_count }}
                    </strong>
                  </span>
                </span>
              </div>

              <div>
                <BRow class="p-1">
                  <BCol cols="6">
                    <div class="text-black text-[16px]">
                      <span>
                        <strong>
                          Minggu ini
                        </strong>
                      </span>
                    </div>
                    <div>
                      <span class="text-black text-[20px]">
                        <strong>
                          {{ userRegisterCount.weekly_count }}
                        </strong>
                      </span>
                    </div>
                  </BCol>
                  <BCol cols="6">
                    <div>
                      <span class="text-black text-[16px]">
                        <strong>
                          Bulan ini
                        </strong>
                      </span>
                    </div>
                    <div>
                      <span class="text-black text-[20px]">
                        <strong>
                          {{ userRegisterCount.monthly_count }}
                        </strong>
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>
            </div>
          </BCol>

          <BCol
            sm="auto"
            xs="10"
            class="mb-1"
          >
            <div class="card-users mb-2">
              <div class="d-flex align-items-center pt-1 pl-1 mb-50">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/link-1.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black">
                  <strong>
                    Checkout Link
                  </strong>
                </span>
              </div>

              <div
                style="border-bottom: 1px solid #E2E2E2;"
                class="pb-1"
              >
                <span class="text-black text-[20px] p-1">
                  Total : <span class="text-info">
                    <strong>
                      {{ checkoutLinksCount.total }}
                    </strong>
                  </span>
                </span>
              </div>

              <div>
                <BRow class="p-1">
                  <BCol cols="6">
                    <div class="text-black text-[16px]">
                      <span>
                        <strong>
                          Minggu ini
                        </strong>
                      </span>
                    </div>
                    <div>
                      <span class="text-black text-[20px]">
                        <strong>
                          {{ checkoutLinksCount.thisWeek }}
                        </strong>
                      </span>
                    </div>
                  </BCol>
                  <BCol cols="6">
                    <div>
                      <span class="text-black text-[16px]">
                        <strong>
                          Bulan ini
                        </strong>
                      </span>
                    </div>
                    <div>
                      <span class="text-black text-[20px]">
                        <strong>
                          {{ checkoutLinksCount.thisMonth }}
                        </strong>
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>
            </div>
          </BCol>

          <BCol
            sm="auto"
            xs="10"
            class="mb-1"
          >
            <div class="card-users mb-2">
              <div class="d-flex align-items-center pt-1 pl-1 mb-50">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/box.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black">
                  <strong>
                    Orderan
                  </strong>
                </span>
              </div>

              <div
                style="border-bottom: 1px solid #E2E2E2;"
                class="pb-1"
              >
                <span class="text-black text-[20px] p-1">
                  Total : <span class="text-info">
                    <strong>
                      {{ ordersCount.total }}
                    </strong>
                  </span>
                </span>
              </div>

              <div>
                <BRow class="p-1">
                  <BCol cols="6">
                    <div class="text-black text-[16px]">
                      <span>
                        <strong>
                          Minggu ini
                        </strong>
                      </span>
                    </div>
                    <div>
                      <span class="text-black text-[20px]">
                        <strong>
                          {{ ordersCount.thisWeek }}
                        </strong>
                      </span>
                    </div>
                  </BCol>
                  <BCol cols="6">
                    <div>
                      <span class="text-black text-[16px]">
                        <strong>
                          Bulan ini
                        </strong>
                      </span>
                    </div>
                    <div>
                      <span class="text-black text-[20px]">
                        <strong>
                          {{ ordersCount.thisMonth }}
                        </strong>
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol cols="12">
            <Chart />
          </BCol>
        </BRow>
      </BCard>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard body-class="custom-card-body">
        <BRow class="justify-content-between align-items-center p-2">
          <BCol cols="auto">
            <span class="text-black text-[18px]">
              <strong>
                Detail Users
              </strong>
            </span>
          </BCol>
          <BCol
            cols="auto"
            class="d-flex align-items-center"
          >
            <div class="input-group mr-1">
              <div class="input-group-prepend">
                <BImg
                  class="input-group-text pr-0"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                  width="45"
                  style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"
                />
              </div>
              <input
                v-model="searchUser"
                type="text"
                class="form-control border-left-0"
                placeholder="Cari Pengguna"
                style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;"
                @input="searchsUserDetails()"
              >
            </div>

            <div class="dropdown dropdown-chart">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ dropdownUserDetail.find(item => item.value === userDetail.period).label }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownUserDetail"
                  :key="index"
                  class="dropdown-item"
                  @click="filter(item.value)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow class="px-1">
          <div
            class="table-responsive table-user-detail"
          >
            <table class="table">
              <thead class="custom-head">
                <tr>
                  <td>
                    <span>
                      <strong>
                        Nama
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        Email
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        No Whatsapp
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        Checkout Link Aktif
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        Total Order
                      </strong>
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userDetail.listItem"
                  :key="index"
                  class="text-black custom-body"
                >
                  <td>
                    {{ item.name }}
                  </td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.no_whatsapp }}</td>
                  <td>{{ item.active_checkout_links }}</td>
                  <td>{{ item.total_order }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </BRow>

        <BRow class="p-2 justify-content-between">
          <BCol
            cols="auto"
            class="d-flex align-items-center"
          >
            <div class="mr-1">
              <span class="text-black">
                List per halaman:
              </span>
            </div>
            <div class="wrapper-perpage d-flex align-items-center justify-content-around">
              <div
                v-for="(item, index) in perPageUserDetail"
                :key="index"
                :class="item === userDetail.perPage ? 'perpage active' : 'perpage'"
                @click="setPageUserDetail(item)"
              >
                {{ item }}
              </div>
            </div>
          </BCol>

          <BCol cols="auto">
            <b-pagination
              v-model="userDetail.currentPage"
              :total-rows="userDetail.totalRows"
              :per-page="userDetail.perPage"
            />
          </BCol>
        </BRow>
      </BCard>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard>
        <BRow class="mb-2">
          <span class="text-black text-[18px] ml-1">
            <strong>
              Checkout Link
            </strong>
          </span>
        </BRow>

        <BRow class="mb-2">
          <BCol
            sm="4"
            xs="6"
            class="mb-1"
          >
            <div class="card-checkout-link d-flex align-items-center p-1">
              <div class="mr-2">
                <BImg src="https://storage.googleapis.com/komerce/assets/illustration/checkout-all-link.svg" />
              </div>
              <div class="d-flex flex-column">
                <span class="text-black mb-50">
                  <strong>
                    Semua
                  </strong>
                </span>

                <span class="text-black text-[20px]">
                  <strong>
                    {{ elementCountStatusItem.all }}
                  </strong>
                </span>
              </div>
            </div>
          </BCol>
          <BCol
            sm="4"
            xs="6"
            class="mb-1"
          >
            <div class="card-checkout-link d-flex align-items-center p-1">
              <div class="mr-2">
                <BImg src="https://storage.googleapis.com/komerce/assets/illustration/checkout-link-active.svg" />
              </div>
              <div class="d-flex flex-column">
                <span class="text-black mb-50">
                  <strong>
                    Aktif
                  </strong>
                </span>

                <span class="text-black text-[20px]">
                  <strong>
                    {{ elementCountStatusItem.active }}
                  </strong>
                </span>
              </div>
            </div>
          </BCol>
          <BCol
            sm="4"
            xs="6"
            class="mb-1"
          >
            <div class="card-checkout-link d-flex align-items-center p-1">
              <div class="mr-2">
                <BImg src="https://storage.googleapis.com/komerce/assets/illustration/checkout-not-active-link.svg" />
              </div>
              <div class="d-flex flex-column">
                <span class="text-black mb-50">
                  <strong>
                    Tidak Aktif
                  </strong>
                </span>

                <span class="text-black text-[20px]">
                  <strong>
                    {{ elementCountStatusItem.inactive }}
                  </strong>
                </span>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow class="justify-content-between align-items-center">
          <BCol
            cols="auto"
            class="d-flex flex-column"
          >
            <span class="text-black text-[18px]">
              <strong>
                Statistik Penggunaan Elemen
              </strong>
            </span>
            <span class="text-black text-[16px]">
              <strong>
                Penggunaan Standar
              </strong>
            </span>
          </BCol>

          <BCol cols="auto">
            <div class="dropdown dropdown-chart-link">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ valueFilterElementStd }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownElementChart"
                  :key="index"
                  class="dropdown-item"
                  @click="filterStatusStandartChart(item)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol cols="12">
            <ChartStandarElements ref="ChartStandarElements" />
          </BCol>
        </BRow>

        <BRow class="mb-2">
          <BCol cols="12">
            <div style="height: 1px; width: 100%; background: black;" />
          </BCol>
        </BRow>

        <BRow class="justify-content-between align-items-center">
          <BCol
            cols="auto"
          >
            <span class="text-black text-[16px]">
              <strong>
                Penggunaan Custom
              </strong>
            </span>
          </BCol>

          <BCol cols="auto">
            <div class="dropdown dropdown-chart-link">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ valueFilterElementCustom }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownElementChart"
                  :key="index"
                  class="dropdown-item"
                  @click="filterStatusCustomChart(item)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol cols="12">
            <ChartCustomELements ref="ChartCustomELements" />
          </BCol>
        </BRow>
      </BCard>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard>
        <BRow class="justify-content-between align-items-center mb-2">
          <BCol cols="auto">
            <span class="text-black text-[18px]">
              <strong>
                Total Orderan
              </strong>
            </span>
          </BCol>
          <BCol
            cols="auto"
            class="d-flex align-items-center"
          >
            <div class="dropdown dropdown-chart-total">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ valueFilterStatusChart }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownStatusChart"
                  :key="index"
                  class="dropdown-item"
                  @click="filterStatusCountChart(item)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol
            sm="3"
            xs="12"
            class="mb-1"
          >
            <ul class="list-group wrapper-list-status-order">
              <li
                v-for="(item, index) in ordersCountStatusItem"
                :key="index"
                class="list-group-item d-flex justify-content-between align-items-center text-black"
              >
                <span
                  :class="colourList(item.label)"
                  class="w-5 h-5 rounded-circle"
                />
                {{ showLabelOrdersStatus(item.label) }}
                <strong>{{ item.label === 'CLOSING_RATIO' ? item.value.toFixed(2) : item.value }}</strong>
              </li>
            </ul>
          </BCol>

          <BCol
            sm="9"
            xs="12"
            class="w-full"
          >
            <ChartsOrderan :params="valueFilterStatusChart" />
          </BCol>
        </BRow>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import Chart from './components/Charts.vue'
import ChartStandarElements from './components/ChartStandarElements.vue'
import ChartCustomELements from './components/ChartCustomElements.vue'
import ChartsOrderan from './components/ChartsOrderan.vue'

export default {
  components: {
    Chart,
    ChartStandarElements,
    ChartCustomELements,
    ChartsOrderan,
  },
  data() {
    return {
      dropdownUserDetail: [
        {
          label: 'Bulan Ini',
          value: 'BULAN_INI',
        },
        {
          label: 'Minggu Ini',
          value: 'MINGGU_INI',
        },
        {
          label: 'Semua',
          value: 'SEMUA_WAKTU',
        },
      ],

      dropdownElementChart: [
        {
          label: 'Semua Form',
          value: 'SEMUA_FORM',
        },
        {
          label: 'Form Aktif',
          value: 'FORM_AKTIF',
        },
      ],

      dropdownStatusChart: [
        {
          label: 'Bulan Ini',
          value: 'BULAN_INI',
        },
        {
          label: 'Minggu Ini',
          value: 'MINGGU_INI',
        },
        {
          label: 'Semua',
          value: 'SEMUA_WAKTU',
        },
      ],
      valueFilterElementStd: 'Semua Form',
      valueFilterElementCustom: 'Semua Form',
      searchUser: '',
      valueFilterStatusChart: 'Semua',

      perPageUserDetail: [5, 20, 50],
    }
  },
  computed: {
    ...mapState('komformDashboard', [
      'userRegisterCount',
      'userDetail',
      'checkoutLinksCount',
      'ordersCount',
      'elementCountStatusItem',
      'ordersCountStatusItem',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'userDetail.currentPage': function () {
      this.actionsUserDetails()
    },
  },
  async mounted() {
    this.actionsRegisterCount()
    this.actionsUserDetails()
    this.actionCheckoutLinksCount()
    this.actionOrdersCount()
    this.actionOrdersCountStatus()
    this.actionOrderCountStatusChart()
    await this.$store.dispatch('komformDashboard/elementsCountStatus')
  },
  methods: {
    actionsRegisterCount() {
      this.$store.dispatch('komformDashboard/registerCount').then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionsUserDetails() {
      this.$store.dispatch('komformDashboard/listUserDetails',
        {
          page: this.userDetail.currentPage,
          limit: this.userDetail.perPage,
          period: this.userDetail.period,
          username: this.searchUser,
        })
        .then(
          () => {},
          err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
          },
        )
    },
    searchsUserDetails() {
      if (this.searchUser.length > 2) {
        this.actionsUserDetails()
      }
    },
    actionCheckoutLinksCount() {
      this.$store.dispatch('komformDashboard/checkoutLinksCount').then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionOrdersCount() {
      this.$store.dispatch('komformDashboard/orderCount').then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionOrdersCountStatus() {
      const payload = this.dropdownStatusChart.find(item => item.label === this.valueFilterStatusChart)
      this.$store.dispatch('komformDashboard/orderCountStatus', payload.value).then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionOrderCountStatusChart() {
      const payload = this.dropdownStatusChart.find(item => item.label === this.valueFilterStatusChart)
      this.$store.dispatch('komformDashboard/orderCountStatusChart', payload.value).then(() => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    showLabelOrdersStatus(data) {
      let result
      switch (data) {
        case ('CLOSING_RATIO'):
          result = 'Ratio Closing'
          break
        case ('ORDER'):
          result = 'Order'
          break
        case ('CLOSING'):
          result = 'Closing'
          break
        case ('TIDAK_CLOSING'):
          result = 'Tidak Closing'
          break
        default:
          break
      }
      return result
    },
    colourList(data) {
      let result
      switch (data) {
        case ('CLOSING_RATIO'):
          result = 'bg-[#08A0F7]'
          break
        case ('ORDER'):
          result = 'bg-[#FBA63C]'
          break
        case ('CLOSING'):
          result = 'bg-[#34A770]'
          break
        case ('TIDAK_CLOSING'):
          result = 'bg-[#F95031]'
          break
        default:
          break
      }
      return result
    },
    filterStatusCountChart(state) {
      this.valueFilterStatusChart = state.label
      this.actionOrderCountStatusChart()
      this.actionOrdersCountStatus()
    },
    filter(state) {
      this.userDetail.period = state
      this.actionsUserDetails()
    },
    setPageUserDetail(page) {
      this.userDetail.perPage = page
      this.actionsUserDetails()
    },
    filterStatusCustomChart(state) {
      this.valueFilterElementCustom = state.label
      this.$refs.ChartCustomELements.setFilter(state)
    },
    filterStatusStandartChart(state) {
      this.valueFilterElementStd = state.label
      this.$refs.ChartStandarElements.setFilter(state)
    },
  },
}
</script>

<style scoped>
  .card-users {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    background: #fff;
    box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.12);
    min-width: 350px;
    min-height: 175px;
  }

  .dropdown-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    width: 180px;
  }

  .custom-head {
    height: 50px;
    background: #F4F4F4;
    border-bottom: 2px solid #E2E2E2;
  }

  .custom-body {
    height: 60px;
  }

  .custom-card-body {
    padding: 0;
  }

  .wrapper-perpage {
    border-radius: 12px;
    background: #F8F8F8;
    width: 150px;
    height: 40px;
  }

  .perpage {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
  }

  .perpage:hover {
    background:#ffbcb0;
    color: white;
  }
  .perpage.active {
    border-radius: 8px;
    background: #F95031;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
  }

  .card-checkout-link {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .dropdown-chart-link, .dropdown-chart-total {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .table-user-detail {
    height: 370px;
  }
</style>
