<template>
  <div
    id="chart"
    class="w-full wrapper-chart"
  >
    <div class="m-2 d-flex justify-content-between align-items-center">
      <div>
        <span class="text-[18px] text-black">
          <strong>
            {{ dropdownTypeFilter.find(item => item.value === typeChart).label }}
          </strong>
        </span>
      </div>

      <div>
        <div class="d-flex align-items-center">
          <div class="dropdown dropdown-chart mr-1">
            <button
              class="btn d-flex align-items-center p-0"
              type="button"
              data-toggle="dropdown"
              style="padding: 5px 10px!important"
            >
              {{ dropdownTypeFilter.find(item => item.value === typeChart).label }}

              <BImg
                class="ml-1"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
              />
            </button>
            <div
              class="dropdown-menu"
              style="z-index: 99;"
            >
              <a
                v-for="(item, index) in dropdownTypeFilter"
                :key="index"
                class="dropdown-item"
                @click="filterType(item)"
              >{{ item.label }}</a>
            </div>
          </div>

          <div class="dropdown dropdown-chart">
            <button
              class="btn d-flex align-items-center p-0"
              type="button"
              data-toggle="dropdown"
              style="padding: 5px 10px!important"
            >
              {{ dropdownTimeFilter.find(item => item.value === periodTime).label }}
              <BImg
                class="ml-1"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
              />
            </button>
            <div
              class="dropdown-menu"
              style="z-index: 99;"
            >
              <a
                v-for="(item, index) in dropdownTimeFilter"
                :key="index"
                class="dropdown-item"
                @click="filterTime(item)"
              >{{ item.label }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <VueApexCharts
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: { VueApexCharts },
  data() {
    return {
      series: [{
        name: 'User Terdaftar',
        data: [],
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'number',
          categories: [],
        },
        tooltip: {
          x: {
            format: 'dd',
          },
        },
      },

      typeChart: 'user',
      dropdownTypeFilter: [
        {
          label: 'User Terdaftar',
          value: 'user',
        },
        {
          label: 'Checkout Link',
          value: 'link',
        },
        {
          label: 'Orderan',
          value: 'order',
        },
      ],
      dropdownTimeFilter: [
        {
          label: 'Bulan Ini',
          value: 'BULAN_INI',
        },
        {
          label: 'Minggu Ini',
          value: 'MINGGU_INI',
        },
        {
          label: 'Semua',
          value: 'SEMUA_WAKTU',
        },
      ],
      periodTime: 'BULAN_INI',
    }
  },
  computed: {
    ...mapState('komformDashboard', ['registerChart', 'checkoutLinksChartItem', 'orderChartItem']),
  },
  mounted() {
    this.initiateChart()
  },
  methods: {
    initiateChart() {
      this.fetchRegisterChart()
    },

    fetchRegisterChart() {
      this.$store.dispatch('komformDashboard/registerChart', this.periodTime).then(
        () => {
          const newData = this.registerChart.map(item => item.count)
          const newXaxis = this.registerChart.map(item => item.day)
          this.chartOptions.xaxis.categories = newXaxis
          this.series = [{
            data: newData,
          }]
        },
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },

    fetchCheckoutLinksChart() {
      this.$store.dispatch('komformDashboard/checkoutLinksChart', this.periodTime).then(
        () => {
          const newData = this.checkoutLinksChartItem.map(item => item.count)
          const newXaxis = this.checkoutLinksChartItem.map(item => item.day)
          this.chartOptions.xaxis.categories = newXaxis
          this.series = [{
            data: newData,
          }]
        },
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },

    fetchOrderChart() {
      this.$store.dispatch('komformDashboard/orderChart', this.periodTime).then(
        () => {
          const newData = this.orderChartItem.map(item => item.count)
          const newXaxis = this.orderChartItem.map(item => item.day)
          this.chartOptions.xaxis.categories = newXaxis
          this.series = [{
            data: newData,
          }]
        },
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },

    filterType(state) {
      this.typeChart = state.value
      switch (state.value) {
        case ('user'):
          this.fetchRegisterChart()
          break
        case ('order'):
          this.fetchOrderChart()
          break
        case ('link'):
          this.fetchCheckoutLinksChart()
          break
        default:
          break
      }
    },

    filterTime(state) {
      this.periodTime = state.value
      switch (this.typeChart) {
        case ('user'):
          this.fetchRegisterChart()
          break
        case ('order'):
          this.fetchOrderChart()
          break
        case ('link'):
          this.fetchCheckoutLinksChart()
          break
        default:
          break
      }
    },
  },
}
</script>

<style scoped>
  .wrapper-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .dropdown-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }
</style>
