<template>
  <div
    id="chart"
  >
    <VueApexCharts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'

export default {
  components: { VueApexCharts },
  data() {
    return {
      series: [{
        data: [],
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 16,
            horizontal: true,
            barHeight: 30,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#000000'],
          },
          offsetX: 20,
        },
        xaxis: {
          categories: [],
        },
      },
      params: {
        category: 'STANDARD',
        filter: 'SEMUA_FORM',
      },
    }
  },

  computed: {
    ...mapState('komformDashboard', ['elementsStandardChartItem']),
  },

  mounted() {
    this.fetchELements()
  },

  methods: {
    fetchELements() {
      this.$store.dispatch('komformDashboard/elementsChart', this.params).then(
        () => {
          const newData = this.elementsStandardChartItem.map(item => item.count)
          this.elementsStandardChartItem.forEach(item => this.chartOptions.xaxis.categories.push(item.type))
          this.series = [{
            data: newData,
          }]
        },
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    setFilter(filter) {
      this.params.filter = filter.value
      this.chartOptions.xaxis.categories = []
      this.fetchELements()
    },
  },
}
</script>

<style scoped>
  .wrapper-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .dropdown-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }
</style>
